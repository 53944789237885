/*
  result = {
    "UserName" : "Test",
    "DateTime" : "Test",
    "ConnectionType" : "Test",
    "CoverageCase" : "Test",
    "NetworkPath" : "Test",
    "TestId" : "Test",
    "Notes" : "Test",
    "ServerAddress" : "Test",
    "ServerLocation" : "Test",
    "ClientAddress" : "Test",
    "ClientLocation" : "Test",
    "Latency" : "Test",
    "Jitter" : "Test",
    "UploadThroughput" : "Test",
    "DownloadThroughput" : "Test",
    "Retransmission" : "Test",
    }

*/

// Mapping of DOM element id's to JSON key names and if the element is mandatory
const userInputValuesFromDom = [
  { "name": "UserName", "id": "input-username", "required": true },
  { "name": "ConnectionType", "id": "input-connection-type", "required": true },
  { "name": "CoverageCase", "id": "input-coverage-case", "required": true },
  { "name": "NetworkPath", "id": "input-network-path", "required": true },
  { "name": "GdceConnectionMode", "id": "input-gdce-connection-mode", "required": true },
  { "name": "ClientLocation", "id": "input-client-location", "required": false },
  { "name": "Notes", "id": "input-notes", "required": false },
]

// Retrieves the DOM element value for the given ID and throws excpetion if not set.
function getValue(name, key, required){
  try {
    let val = document.getElementById(key)
    if(val)  {
      if (val.value) return val.value // for <input>
      if (val.textContent) return val.textContent // for <div>
      throw name + " is not set"
    }
    throw name + " not found"
  } catch(err){
    if (required) {
      throw err
    }
  }
}

// returns the user input values
function getUserInfo(userInputs) {
  let results = {};
  for (let i=0; i< userInputValuesFromDom.length; i++) {
    let key = userInputValuesFromDom[i]['name'];
    if (userInputs[key] && userInputs[key].value) {
      results[userInputValuesFromDom[i]['name']] = userInputs[key].value;
    } else if (userInputValuesFromDom[i]['required']) {
      throw "Input parameter '" + key + "' is not set"
    }
    // Proceed with next if required==false
  }
  return results
}

// returns metrics, takes an argument which is the prefix to identify gcp or gdce
function getMetrics(key){
  return {
   "Latency": getValue("Latency", key+"-ndt-latency", true),
   "Jitter": getValue("Jitter", key+"-ndt-jitter", true),
   "UploadThroughput": getValue("UploadThroughput", key+"-ndt-upload", true),
   "DownloadThroughput": getValue("DownloadThroughput", key+"-ndt-download", true),
   "Retransmission": getValue("Retransmission", key+"-ndt-retransmission", true),
   "ServerAddress": getValue("ServerAddress", key+"-endpoint-mlab", true),
  }
}

// Invokes /push api with metrics json
async function send(result, successCallback, errCallback) {
  const urlObject = new URL(window.location.href);
  const url = urlObject.protocol + "//" + urlObject.host + "/push";
  fetch(url, 
      {
        method: 'POST',
        body: JSON.stringify(result),
      }
  ).then((resp) => {
    if(resp.ok){
      successCallback();
    } else {
      errCallback("Failed to save results: " + resp.statusText);
    }
  }).catch((error) => {
    errCallback("Failed to save results: " + error);
  });
}

// pushResults will be called from main script to send the results of gdce and gcp endpoints to GCS
export async function pushResults(userInputs, successCallback, errCallback) {
  try {
    let userinfo = getUserInfo(userInputs);
    userinfo['DateTime'] = new Date();

    // Check if one of the test was executed, if so submit else exit
    let gdceMetrics, gcpMetrics;
    try {
      gdceMetrics = getMetrics('gdce')
      gdceMetrics['Infra'] = 'GDCE';
    } catch {

    }
    try {
      gcpMetrics = getMetrics('gcp');
      gcpMetrics['Infra'] = 'GCP';
    } catch {

    }

    if (gcpMetrics != undefined && gdceMetrics != undefined) {
      send(Object.assign({}, userinfo, gdceMetrics), 
            function(){
              send(Object.assign({}, userinfo, gcpMetrics), successCallback, errCallback);
            }, 
            errCallback);    
    } else if (gdceMetrics != undefined) {
      send(Object.assign({}, userinfo, gdceMetrics), successCallback, errCallback); 
    } else if (gcpMetrics != undefined) {
      send(Object.assign({}, userinfo, gcpMetrics), successCallback, errCallback); 
    } else {
      errCallback("Execute a test to save the result")
    }
  } catch(err) {
    errCallback(err)
  }
}
