/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/** 
 * TODO:
 * Adding as separate page
 * Add multiple endpoints as a table to add a row and run/retry test for each seperately via the button on that row
 * Add client details via Geo IP
 * Add GDCE PING dashboard code here
 * Handle avoiding the duplicate submission when the button is pressed multiple times
 * 
**/


import { MDCDialog } from "@material/dialog";
import { MDCDataTable } from "@material/data-table";
import { MDCTooltip } from "@material/tooltip";
import { MDCTextField } from '@material/textfield';
import { MDCSelect } from '@material/select';

import { pushResults } from "./perf.js"; // To send results to GCS bucket
var ndt7 = require('./ndt7.js'); // ndt module directly added here instead of npm import

// GLOBAL
window.PUSH_RESULTS_DONE = false;

const GLOBAL_REGION_KEY = "global";

const PING_TEST_RUNNING_STATUS = "running"; // GCPING STATUS
const PING_TEST_STOPPED_STATUS = "stopped";
const MLAB_TEST_RUNNING_STATUS = "running"; // MLAB STATUS
const MLAB_TEST_STOPPED_STATUS = "stopped";

const btnCtrlGcping = document.getElementById("stopstart-gcping");
const btnCtrlMlab = document.getElementById("stopstart-mlab");
const btnCtrlSubmitResult = document.getElementById("submit-result");

let edgeEndpoint, gcpEndpoint, edgeEndpointMlab, gcpEndpointMlab;
let inputs = {};



/**
 * The `regions` obj is of the following format:
 * {
 *  "us-east1": {
 *    "key": "",
 *    "label": "",
 *    "pingUrl": "",
 *    "latencies": [],
 *    "median": ""
 *  }
 * }
 */
const regions = {};
const results = []; // this will always be sorted according to sortKey and sortDir

let pingTestStatus = PING_TEST_RUNNING_STATUS;
var mlabTestStatus = MLAB_TEST_RUNNING_STATUS;
let fastestRegionVisible = false;
let fastestRegion = null;
let globalRegionProxy = "";
let sortKey = "median"; // column to sort the data with
let sortDir = "ascending"; // sorting direction(ascending/descending)


/**
 * Function to update the current status of pinging
 * @param {string} status
 */
 function updatePingTestState(status) {
  pingTestStatus = status;
  if (status === PING_TEST_RUNNING_STATUS) {
    btnCtrlGcping.classList.add("running");
  } else if (status === PING_TEST_STOPPED_STATUS) {
    btnCtrlGcping.classList.remove("running");
  }
}

/**
 * Function to update the current status of pinging
 * @param {string} status
 */
 function updateMlabTestState(status) {
  mlabTestStatus = status;
  if (status === MLAB_TEST_RUNNING_STATUS) {
    btnCtrlMlab.classList.add("running");
  } else if (status === MLAB_TEST_STOPPED_STATUS) {
    btnCtrlMlab.classList.remove("running");
  }
}

/**
 * Event listener for the button to start/stop the pinging
 */
 btnCtrlGcping.addEventListener("click", function () {
  const newStatus =
    pingTestStatus === PING_TEST_STOPPED_STATUS
      ? PING_TEST_RUNNING_STATUS
      : PING_TEST_STOPPED_STATUS;
  updatePingTestState(newStatus);

  if (newStatus === PING_TEST_RUNNING_STATUS) {
    runTestsGcping();
  }
});

btnCtrlMlab.addEventListener("click", function () {
  if(mlabTestStatus == MLAB_TEST_RUNNING_STATUS) {
    ndt7.stopWorkers()
  }
  const newStatus =
    mlabTestStatus === MLAB_TEST_STOPPED_STATUS
      ? MLAB_TEST_RUNNING_STATUS
      : MLAB_TEST_STOPPED_STATUS;
  updateMlabTestState(newStatus);

  if (newStatus === MLAB_TEST_RUNNING_STATUS) {
    window.PUSH_RESULTS_DONE = false;
    runTestsMlab();
  }
});

btnCtrlSubmitResult.addEventListener("click", function () {

  if(window.PUSH_RESULTS_DONE) {
    alert("Results saved already, rerun test to submit new data")
  } else {
    btnCtrlSubmitResult.classList.add("running");
    pushResults(inputs, 
      // successCallback
      function(){
        alert("Results saved successfully");
        window.PUSH_RESULTS_DONE = true;
        btnCtrlSubmitResult.classList.remove("running");
      }, 
      // errCallback
      function(msg){
        alert(msg);
        btnCtrlSubmitResult.classList.remove("running");
      }
    );
  }
});

/**
 * Ping all regions to fetch their latency
 *
 * @param {number} iter
 */
async function pingAllRegions(iter) {
  if (edgeEndpoint) {
    if (edgeEndpoint.value != "" && edgeEndpoint.value.length>6){
        regions["edge"] = {
          key: "edge",
        label: "GDCE Endpoint",
        pingUrl: "http://" + edgeEndpoint.value + "/api/ping",
          latencies: [],
          median: "",
        }
    }
  }
  if (gcpEndpoint) {
    if (gcpEndpoint.value != "" && gcpEndpoint.value.length>6){
      regions["gcp"] = {
        key: "gcp",
        label: "GCP Endpoint",
        pingUrl: "http://" + gcpEndpoint.value + "/api/ping",
        latencies: [],
        median: "",
      }
    }
  }
  const regionsArr = Object.values(regions);

  for (let i = 0; i < iter; i++) {
    for (const region of regionsArr) {
      // Takes care of the stopped button
      if (pingTestStatus === PING_TEST_STOPPED_STATUS) {
        break;
      }

      const latency = await pingSingleRegion(region.key);

      // add the latency to the array of latencies
      // from where we can compute the median and populate the table
      regions[region.key]["latencies"].push(latency);
      regions[region.key]["median"] = getMedian(
        regions[region.key]["latencies"],
      );

      // update fastest region
      if (
        fastestRegion === null ||
        regions[region.key]["median"] < regions[fastestRegion]["median"]
      ) {
        fastestRegion = region.key;
      }

      addResult(region.key);
      updateList();
    }

    // start displaying the fastest region after at least 1 iteration is over.
    // subsequent calls to this won't change anything
    displayFastest(true);
  }

  // when all the region latencies have been fetched, let's update our status flag
  updatePingTestState(PING_TEST_STOPPED_STATUS);
}

/**
 * Computes the ping time for a single GCP region
 * @param {string} regionKey The key of the GCP region, ex: us-east1
 * @return {Promise} Promise
 */
function pingSingleRegion(regionKey) {
  return new Promise((resolve) => {
    const gcpZone = regions[regionKey];
    const start = new Date().getTime();

    fetch(gcpZone.pingUrl, {
      mode: "no-cors",
      cache: "no-cache",
    }).then(async (resp) => {
      const latency = new Date().getTime() - start;

      // if we just pinged the global region, the response should contain
      // the region that the Global Load Balancer uses to route the traffic.
      if (regionKey === GLOBAL_REGION_KEY) {
        resp.text().then((val) => {
          globalRegionProxy = val.trim();
        });
      }

      resolve(latency);
    });
  });
}


/**
 * Updates the list view with the result set of regions and their latencies.
 */
function updateList() {
  let html = "";
  let cls = "";
  let regionKey = "";

  for (let i = 0; i < results.length; i++) {
    cls =
      results[i] === fastestRegion && fastestRegionVisible
        ? "fastest-region"
        : "";
    regionKey = getDisplayedRegionKey(results[i]);
    html +=
      '<tr class="mdc-data-table__row ' +
      cls +
      '"><td class="mdc-data-table__cell regiondesc">' +
      regions[results[i]]["label"] +
      '<div class="embedded-region d-none d-md-block">' +
      regionKey +
      "</div>" +
      '</td><td class="mdc-data-table__cell region d-md-none">' +
      regionKey +
      "</td>" +
      '<td class="mdc-data-table__cell result"><div>' +
      regions[results[i]]["median"] +
      " ms</div></td></tr>";
  }

  document.getElementsByTagName("tbody")[1].innerHTML = html;
}

/**
 * Helper function to return median from a given array
 * @param {*} arr Array of latencies
 * @return {*}
 */
function getMedian(arr) {
  if (arr.length == 0) {
    return 0;
  }
  const copy = arr.slice(0);
  copy.sort();
  return copy[Math.floor(copy.length / 2)];
}

/**
 * Helper that adds the regionKey to it's proper position keeping the results array sorted
 * This means we don't always have to sort the whole results array
 * TODO: Try and use an ordered map here to simply this
 * @param {string} regionKey
 */
function addResult(regionKey) {
  if (!results.length) {
    results.push(regionKey);
    return;
  }

  // remove any current values with the same regionKey
  for (let i = 0; i < results.length; i++) {
    if (results[i] === regionKey) {
      results.splice(i, 1);
      break;
    }
  }

  // TODO: Probably use Binary search here to merge the following 2 blocks
  // if new region is at 0th position
  if (compareTwoRegions(regionKey, results[0]) < 0) {
    results.unshift(regionKey);
    return;
  }
  // if new region is at last position
  else if (compareTwoRegions(regionKey, results[results.length - 1]) > 0) {
    results.push(regionKey);
    return;
  }

  // add the region to it's proper position
  for (let i = 0; i < results.length - 1; i++) {
    // if the region to be added is b/w i and i+1 elements
    if (
      compareTwoRegions(regionKey, results[i]) >= 0 &&
      compareTwoRegions(regionKey, results[i + 1]) < 0
    ) {
      results.splice(i + 1, 0, regionKey);
      return;
    }
  }
}

/**
 * Sets the visiblity for the fastest region indicator on the list(the green cell)
 * @param {bool} isVisible Indicator to toggle visibility for the fastest region indicator
 */
function displayFastest(isVisible) {
  fastestRegionVisible = true;
  updateList();
}

/**
 * Helper function to deduce the region to be displayed in the list
 * @param {string} regionKey
 * @return {string}
 */
function getDisplayedRegionKey(regionKey) {
  // if the region is not global, return it as it is.
  if (regionKey !== GLOBAL_REGION_KEY) return regionKey;

  // if the region is global and we have received the region that is used by the Gloabl Load Balancer
  // we display that
  if (globalRegionProxy.length > 0)
    return "<em>→" + globalRegionProxy + "</em>";

  // if the region is global and we don't have the routing region, we show "gloabl"
  return "global";
}

/**
 * Sort the table data based on a column(defined in sortKey) and direction(sortDir)
 */
function sortResults() {
  results.sort(compareTwoRegions);
}

/**
 * Function to compare order of 2 regions based on the current sort options
 * @param {string} a Region key for first region to be compared
 * @param {string} b Region key for second region to be compared
 * @return {int}
 */
function compareTwoRegions(a, b) {
  const multiplier = sortDir === "ascending" ? 1 : -1;

  a = regions[a][sortKey];
  b = regions[b][sortKey];

  if (a == b) {
    return 0;
  }

  return multiplier * (a > b ? 1 : -1);
}

/**
 * Updates the list view with the result set of regions and their latencies.
 */
 function updateMlabTable() {
  let html = "";

  if (edgeEndpointMlab && edgeEndpointMlab.value != "" && edgeEndpointMlab.value.length>6) {
    html +=
    '<tr class="mdc-data-table__row">' +
    '<td class="mdc-data-table__cell"><div id="gdce-ndt-endpoint" >' + edgeEndpointMlab.value + '</div></td>' +
    '<td class="mdc-data-table__cell"><div id="gdce-ndt-latency" ></div></td>' +
    '<td class="mdc-data-table__cell"><div id="gdce-ndt-download" ></div></td>' +
    '<td class="mdc-data-table__cell"><div id="gdce-ndt-upload" ></div></td>' +
    '<td class="mdc-data-table__cell"><div id="gdce-ndt-jitter" ></div></td>' +
    '<td class="mdc-data-table__cell"><div id="gdce-ndt-retransmission" ></div></td>' +
    '</tr>';
  } 
  if (gcpEndpointMlab && gcpEndpointMlab.value != "" && gcpEndpointMlab.value.length>6) { 
    html +=
      '<tr class="mdc-data-table__row">' +
      '<td class="mdc-data-table__cell"><div id="gcp-ndt-endpoint" > '+ gcpEndpointMlab.value + '</div></td>' +
      '<td class="mdc-data-table__cell"><div id="gcp-ndt-latency" ></div></td>' +
      '<td class="mdc-data-table__cell"><div id="gcp-ndt-download" ></div></td>' +
      '<td class="mdc-data-table__cell"><div id="gcp-ndt-upload" ></div></td>' +
      '<td class="mdc-data-table__cell"><div id="gcp-ndt-jitter" ></div></td>' +
      '<td class="mdc-data-table__cell"><div id="gcp-ndt-retransmission" ></div></td>' +
      '</tr>';
  }

  document.getElementsByTagName("tbody")[0].innerHTML = html;
}

async function runNdtTest(endpoint, key) {
  let idkey = key + '-ndt-'
  const protocol = ((new URL(window.location.href)).protocol === 'https:') ? 'wss' : 'ws'
  return await ndt7.test(
      {
          userAcceptedDataPolicy: true,
          downloadworkerfile: "ndt7-download-worker.js",
          uploadworkerfile: "ndt7-upload-worker.js",
          server: endpoint,
          protocol: protocol,
          metadata: {
              client_name: 'ndt7-perf-test',
          }
      },
      {
          downloadStart: (data) => {
            document.getElementById(idkey + 'endpoint').parentNode.classList.add('running');
            document.getElementById(idkey + 'download').innerHTML = 'Initializing';
          },
          downloadMeasurement: function (data) {
              if (data.Source === 'client') {
                  document.getElementById(idkey + 'download').innerHTML = data.Data.MeanClientMbps.toFixed(2) + ' Mb/s';
              }
          },
          downloadComplete: function (data) {
              // (bytes/second) * (bits/byte) / (megabits/bit) = Mbps
              const serverBw = data.LastServerMeasurement.BBRInfo.BW * 8 / 1000000;
              const clientGoodput = data.LastClientMeasurement.MeanClientMbps;
              console.log(
                  `Download test is complete:
  Instantaneous server bottleneck bandwidth estimate: ${serverBw} Mbps
  Mean client goodput: ${clientGoodput} Mbps`);
              console.log(data.LastClientMeasurement);
              console.log(data.LastServerMeasurement);
              document.getElementById(idkey + 'download').innerHTML = clientGoodput.toFixed(2) + ' Mb/s';
              document.getElementById(idkey + 'latency').innerHTML = (data.LastServerMeasurement.TCPInfo.MinRTT / 1000).toFixed(0) + ' ms';
              document.getElementById(idkey + 'jitter').innerHTML = (data.LastServerMeasurement.TCPInfo.RTTVar / 1000).toFixed(0) + ' ms';
              document.getElementById(idkey + 'retransmission').innerHTML = (data.LastServerMeasurement.TCPInfo.BytesRetrans /
              data.LastServerMeasurement.TCPInfo.BytesSent * 100).toFixed(2) + '%';
          },
          uploadStart: (data) => {
              document.getElementById(idkey + 'upload').innerHTML = 'Initializing';
              // document.getElementById(idkey + 'upload').parentNode.classList.add('running');
          },
          uploadMeasurement: function (data) {
              if (data.Source === 'server') {
                  document.getElementById(idkey + 'upload').innerHTML = (data.Data.TCPInfo.BytesReceived /
                      data.Data.TCPInfo.ElapsedTime * 8).toFixed(2) + ' Mb/s';;
              }
          },
          uploadComplete: function(data) {
            document.getElementById(idkey + 'endpoint').parentNode.classList.add('success');
            const bytesReceived = data.LastServerMeasurement.TCPInfo.BytesReceived;
              const elapsed = data.LastServerMeasurement.TCPInfo.ElapsedTime;
              // bytes * bits/byte / microseconds = Mbps
              const throughput =
              bytesReceived * 8 / elapsed;
              console.log(
                  `Upload test completed in ${(elapsed / 1000000).toFixed(2)}s
  Mean server throughput: ${throughput} Mbps`);
          },
          error: function (err) {
            document.getElementById(idkey + 'endpoint').parentNode.classList.remove('running');
            document.getElementById(idkey + 'endpoint').parentNode.classList.add('failed');
            console.log('Error while running the test:', err);
          },
      },
  ).then((exitcode) => {
      console.log("ndt7 test completed with exit code:", exitcode)
  });
}

async function runNdtTests() {
  updateMlabTable();
  if (edgeEndpointMlab && edgeEndpointMlab.value != "" && edgeEndpointMlab.value.length>6 ) {
    await runNdtTest(edgeEndpointMlab.value,'gdce')
  }
  if (mlabTestStatus != MLAB_TEST_STOPPED_STATUS && gcpEndpointMlab && gcpEndpointMlab.value != "" && gcpEndpointMlab.value.length>6) {
    await runNdtTest(gcpEndpointMlab.value,'gcp');
  }
  updateMlabTestState(MLAB_TEST_STOPPED_STATUS)
}

function runTestsMlab() {
  runNdtTests();
}

function runTestsGcping() {
  pingAllRegions(3);
}

async function runTests(){
  await pingAllRegions(3);
  await runNdtTests();
}

window.onload = function () {

  // How it works btn
  const howItWorksDialog = new MDCDialog(document.querySelector(".how-it-works-dialog"));
  const instructionsDialog = new MDCDialog(document.querySelector(".instructions-dialog"));

  // Just to fill IPs by URL query params
  (new URL(window.location.href)).searchParams.forEach((x, y) =>
  document.getElementById(y).value = x)
  
  edgeEndpoint = new MDCTextField(document.querySelector('#external-server-gdce'));
  gcpEndpoint = new MDCTextField(document.querySelector('#external-server-gcp'));

  edgeEndpointMlab = new MDCTextField(document.querySelector('#gdce-mlab'));
  gcpEndpointMlab = new MDCTextField(document.querySelector('#gcp-mlab'));
  
  inputs.UserName = new MDCTextField(document.querySelector('#label-input-username'));
  inputs.Notes = new MDCTextField(document.querySelector('#label-input-notes'));

  inputs.GdceConnectionMode = new MDCSelect(document.querySelector('#label-input-gdce-connection-mode'));
  inputs.ConnectionType = new MDCSelect(document.querySelector('#label-input-connection-type'));
  inputs.CoverageCase = new MDCSelect(document.querySelector('#label-input-coverage-case'));
  inputs.NetworkPath = new MDCSelect(document.querySelector('#label-input-network-path'));

  // Show cell signal options when connection type is not wifi
  (inputs.CoverageCase).disabled = true;
  (inputs.ConnectionType).listen('MDCSelect:change', () => {
    (inputs.CoverageCase).disabled = false;
    (inputs.CoverageCase).selectedIndex = -1;
    if( (inputs.ConnectionType).value == "Wifi"){
      document.querySelector('#label-input-coverage-case ul').innerHTML = `<li class="mdc-deprecated-list-item" data-value="">
        <span class="mdc-deprecated-list-item__ripple"></span>
        </li>
        <li class="mdc-deprecated-list-item" data-value="Strong">
          <span class="mdc-deprecated-list-item__ripple"></span>
          <span class="mdc-deprecated-list-item__text">Strong</span>
        </li>
        <li class="mdc-deprecated-list-item" data-value="Weak">
          <span class="mdc-deprecated-list-item__ripple"></span>
          <span class="mdc-deprecated-list-item__text">Weak</span>
        </li>`
    } else {
      document.querySelector('#label-input-coverage-case ul').innerHTML = `<li class="mdc-deprecated-list-item" data-value="">
        <span class="mdc-deprecated-list-item__ripple"></span>
        </li>
        <li class="mdc-deprecated-list-item" data-value="Indoor Poor Coverage">
          <span class="mdc-deprecated-list-item__ripple"></span>
          <span class="mdc-deprecated-list-item__text">Indoor Poor Coverage</span>
        </li>
        <li class="mdc-deprecated-list-item" data-value="Indoor/Outdoor Excellent Coverage (line of sight)">
          <span class="mdc-deprecated-list-item__ripple"></span>
          <span class="mdc-deprecated-list-item__text">Indoor/Outdoor Excellent Coverage (line of sight)</span>
        </li>
        <li class="mdc-deprecated-list-item" data-value="Indoor/Outdoor Excellent Coverage (cell edge)">
          <span class="mdc-deprecated-list-item__ripple"></span>
          <span class="mdc-deprecated-list-item__text">Indoor/Outdoor Excellent Coverage (cell edge)</span>
        </li>`
    }
    (inputs.CoverageCase).layoutOptions();

  });
  

  document
    .querySelector(".how-it-works-link")
    .addEventListener("click", function (e) {
      e.preventDefault();
      howItWorksDialog.open();
    });

  document
    .querySelector(".instructions-link")
    .addEventListener("click", function (e) {
      e.preventDefault();
      instructionsDialog.open();
    });

  // init data-table
  new MDCDataTable(document.querySelector("#mdc-data-table-ping-results"));
  new MDCDataTable(document.querySelector("#mdc-data-table-mlab-results"));

  document
    .querySelector("#mdc-data-table-ping-results")
    .addEventListener("MDCDataTable:sorted", function (data) {
      const detail = data.detail;

      // update the sorting options according to the requested values
      (sortKey = detail.columnId), (sortDir = detail.sortValue);

      sortResults();
      updateList();
    });

  runTests();
  

  // init tooltips
  [].map.call(document.querySelectorAll(".mdc-tooltip"), function (el) {
    return new MDCTooltip(el);
  });

  if (!!location.search && location.search.indexOf("instructions") > -1) {
    document.querySelector(".instructions-link").click();
  }

  if (!!location.search && location.search.indexOf("readme") > -1) {
    document.querySelector(".how-it-works-link").click();
  }
};

